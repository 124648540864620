<template>
  <div class="shipments-page">
    <div class="mb-8 flex justify-between">
      <div class="flex md:flex-row flex-col gap-4">
        <div class="page-title px-6 py-8 flex items-center gap-2">
          <img src="@/assets/images/pages/walletKhazna.svg" width="24" alt="wallet-khazna">
          <div class="lg:text-lg flex gap-2 items-center">
            <span class="text-darkblue font-medium">{{$t('Cash Balance')}}</span> | 
            <span class="font-semibold text-primary">{{Number(khazna.balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}} {{$t('EGP')}}</span>
          </div>
        </div>
        <div class="page-title px-6 py-8 flex items-center gap-2">
          <img src="@/assets/images/pages/walletKhazna.svg" width="24" alt="wallet-khazna">
          <div class="lg:text-lg flex gap-2 items-center">
            <span class="text-darkblue font-medium">{{$t('Shortfall')}}</span> |
            <span class="font-semibold text-red">{{Number(khazna.cumulative_shortfall).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}} {{$t('EGP')}}</span>
          </div>
        </div>
      </div>
      <div class="vx-col lg:mt-0 mt-4 flex self-center items-end">
        <div class="flex justify-between">
          <span class="font-semibold"></span>
          <span class="font-medium text-primary cursor-pointer">
            <vs-button
              @click="openKhaznaModal('add')"
              color="primary"
              icon-pack="feather"
              icon="icon-plus"
              size="large"
            >
            </vs-button>
          </span>
        </div>
      </div>
    </div>
    <div v-if="depositedObj.status && depositedObj.status !== 'completed'" class="flex md:flex-row flex-col justify-between items-center gap-4 border border-solid border-grey rounded-lg p-5 mb-4">
      <div class="flex items-center gap-4">
        <img src="@/assets/images/pages/exportKhazna.svg">
        <p class="text-blue-900 font-medium text-lg">
          <span>{{$t('Today\'s Required Deposit')}}</span> | 
          <span class="text-green">{{ depositedObj.requested_amount > 0 ? Number(depositedObj.requested_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}} {{$t('EGP')}}</span>
          <span class="block text-darkgray text-base">{{$t('Comment') + ': ' + depositedObj.comment}}</span>
        </p>
      </div>
      <vs-button @click="openDepositModal()" size="medium" color="green" class="font-medium ml-auto">{{ $t('Withdraw from Khazna to ShipBlu') }}</vs-button>
    </div>
    <div class="flex sm:flex-row flex-col sm:items-center">
      <head-component style="flex-grow: 0 !important;" :noActions="true" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"></head-component>
      <filter-date class="mb-4 p-2" :filterDateModal="filterDateModal" :startDate="startDate" :endDate="endDate" @filterDateModal="filterDateModal = $event" @startDate="startDate = $event" @endDate="endDate = $event" @loadTransactions="loadTransactions"/>
    </div>
    <shipblu-table :hideHeader="true" orders v-model="selected" multiple :sst="true" :data="transactions" :tableLoader="tableLoader">
      <template slot="thead">
        <shipblu-th>{{$t('ID')}}</shipblu-th>
        <shipblu-th>{{$t('Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Running Balance')}}</shipblu-th>
        <shipblu-th>{{$t('Transaction Type')}}</shipblu-th>
        <shipblu-th>{{$t('Issued By')}}</shipblu-th>
        <shipblu-th>{{$t('Deposit Date')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Reference Number')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].id" :style="`color: ${getStyle(data[indextr])}`">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('ID')}}</p>
            <p class="sm:text-base text-sm">
              <span class="text-black">{{ data[indextr].id }}</span>
            </p>
          </shipblu-td>

          <shipblu-td class="order-12" :data="data[indextr].amount">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <span :class="data[indextr].amount && data[indextr].amount > 0 ? '' : 'text-red'">
                {{ data[indextr].amount ? data[indextr].amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00' }}
              </span>
            </div>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].running_balance">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Running Balance')}}</p>
            <p class="sm:text-base text-sm">
              {{ (data[indextr].running_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].type">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Transaction Type')}}</p>
            <p class="sm:text-base text-sm">
              {{ $t(data[indextr].type) }}
            </p>
            <a class="block mt-2" v-if="data[indextr].type === 'withdraw'" @click="downloadTransaction(data[indextr])" target="_blank" >
              <feather-icon class="help-icon cursor-pointer" icon="DownloadIcon" svgClasses="w-6 h-6 text-primary"></feather-icon>
            </a>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].by">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Issued By')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].by }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].by">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Deposit Date')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].deposit_date }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].created">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Created On')}}</p>
            <p class="sm:text-base text-sm">{{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }}</p>
            <p class="sm:text-base text-sm">{{ new Date(data[indextr].created).toLocaleTimeString('en-GB') }}</p>
          </shipblu-td>

          <shipblu-td class="order-10" :data="data[indextr].reference_number">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Reference Number')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].reference_number }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-11" :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-khazna-transaction :khaznaTransactionModal="khaznaTransactionModal" @khaznaTransactionModal="khaznaTransactionModal = $event" :khazna="khazna" :type="type" @loadTransactions="loadKhazna"></add-khazna-transaction>
    <deposit-khazna :depositedObj="depositedObj" :depositModal="depositModal" :khazna="khazna" 
      @depositModal="depositModal = $event" @loadTransactions="loadKhazna"></deposit-khazna>
  </div>
</template>

<script>
import AddKhaznaTransaction from './components/AddKhaznaTransaction.vue'
import DepositKhazna from './components/DepositKhazna.vue'
import common  from '@/assets/utils/common'
import i18nData from '../../i18n/i18nData'
import {sendRequest} from '../../http/axios/requestHelper.js'
import HeadComponent from '../merchant/components/Header.vue'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import FilterDate from '../finance/components/FilterDate.vue'

export default {
  data () {
    return {
      transactions: [],
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      khaznaTransactionModal: false,
      khazna: {},
      type: '',
      warehousesDic: {},
      statusFilters: [
        {
          name: 'All',
          value: 'all'
        },
        {
          name: 'Approved',
          value: 'approved'
        },
        {
          name: 'Pending',
          value: 'pending'
        }
      ],
      filters: '',
      selected: [],
      totalRows: 0,
      tableLoader: false,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      offset: 0,
      depositedObj: {},
      filterDateModal: false,
      startDate: '',
      endDate: '',
      depositModal: false
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: 'head-of-fleet-khazna',
        query: {
          filter: this.filters,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadTransactions()
    },
    '$route.params.warehouseID' () {
      this.loadKhazna()
    },
    selected () {
      this.$emit('selected', this.selected)
    }
  },
  methods: {
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    formatWarehouses (warehouses) {
      warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.code] = warehouse.id
      })
      this.loadKhazna()
    },
    changeFilter () {
      this.filters = this.filterValue
      if (this.filterValue === 'all') {
        this.filters = ''
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          filter: this.filters === '' ? 'all' : this.filters,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadTransactions()
    },
    loadKhazna () {
      sendRequest(false, false, this, `api/v1/accounting/khazna/${this.warehousesDic[this.$route.params.warehouseID]}/`, 'get', null, true, 
        (response) => {
          this.khazna = response.data
          this.loadTransactions()
          this.loadWithdrawRequest()
        }
      )
    },
    loadTransactions () {
      this.tableLoader = true
      const from = this.startDate ? new Date(this.startDate).toISOString().split('T')[0] : ''
      const to = this.endDate ? new Date(this.endDate).toISOString().split('T')[0] : ''
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&status=${this.filters}&date_from=${from}&date_to=${to}`
      sendRequest(true, false, this, `api/v1/accounting/khazna/${this.warehousesDic[this.$route.params.warehouseID]}/transactions/${query}`, 'get', null, true, 
        (response) => {
          this.selected = []
          this.transactions = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
        }
      )
    },
    loadWithdrawRequest () {
      sendRequest(true, false, this, `api/v1/accounting/khazna/${this.khazna.id}/withdraw-requests/`, 'get', null, true, 
        (response) => {
          if (response.data.count > 0) {
            this.depositedObj = response.data.results[0]
          }
        }
      )
    },
    openKhaznaModal (type) {
      this.type = type
      this.khaznaTransactionModal = true
    },
    openDepositModal () {
      this.depositModal = true
    },
    downloadTransaction (data) {
      if (data.deposit_slip_key === '#') {
        this.$vs.notify({
          color:'warning',
          title:i18nData[this.$i18n.locale]['Warning'],
          text:i18nData[this.$i18n.locale]['No Slip Available'],
          position: 'top-center'
        })
      } else {
        sendRequest(false, false, this, `api/v1/accounting/khazna-bank-deposits/${data.id}/pdf/`, 'get', null, true, 
          (response) => {
            const file_URl = response.data.file_url
            window.open(file_URl, '_blank')
          }
        )
      }
    }
  },
  components: {
    AddKhaznaTransaction,
    DepositKhazna,
    HeadComponent,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination,
    FilterDate
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    common.manageLoadWarehouses(this.formatWarehouses, this)
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
<style scoped lang="scss">
.bg-white {
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(157, 173, 194, 0.4);
  border-radius: 6px;
}
</style>