import { render, staticRenderFns } from "./Khazna.vue?vue&type=template&id=4b992877&scoped=true"
import script from "./Khazna.vue?vue&type=script&lang=js"
export * from "./Khazna.vue?vue&type=script&lang=js"
import style0 from "./Khazna.vue?vue&type=style&index=0&id=4b992877&prod&lang=scss"
import style1 from "./Khazna.vue?vue&type=style&index=1&id=4b992877&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b992877",
  null
  
)

export default component.exports